import React from 'react';
import Moment from 'moment';

import Link from 'utils/localized-link';
import Button from '@youship/components/objects/button';
import Banner from 'components/sections/banner';
import Layout from 'components/layout';
import LegalLayout from 'components/legal-layout';
import P69466 from '@youship/legal/projects/p69466';
import BasicLayout from 'components/basic-layout';
import SEO from 'components/seo';
import Image from 'components/image';

import pt2020Image from '../../images/pt2020.png';

const image1 = 'safy-concierge/image1.png';
const image2 = 'safy-concierge/image2.png';
const image3 = 'safy-concierge/image3.png';

const bannerImage = 'home/robot-banner-bottom2.jpg';

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'expertise.banner.buttons.contacts'
  }
];

const SafyConciergePage = ({ pageContext: { locale } }) => (

  <Layout locale={locale}>
    <SEO title="projects.title" />
    <BasicLayout>
      <>
      <div className="">
          <img
                alt="PT2020"
                src={pt2020Image}
              />
       </div>  
      <div>
        <p className="b-layout__center">
        Designação: SafyConcierge <br/>
        Código do projeto: CENTRO-01-02B7-FEDER-069466 <br/>
        Objetivo principal: Reforçar a investigação, o desenvolvimento tecnológico e a inovação <br/>
        Região de intervenção: Programa Operacional Regional do Centro <br/>
        Entidade beneficiária: SHIPNOW TECHNOLOGIES, UNIPESSOAL LDA <br/>
        Data de aprovação: 15-10-2020 <br/>
        Data de início: 01-09-2020 <br/>
        Data de conclusão: 31-05-2021 <br/>
        Custo total elegível: 149.206,25 EUR <br/>
        Apoio financeiro da União Europeia: FEDER 119.365,00 EUR <br/>
        </p>
      </div>
      
      <div>
        <h3>
          Descrição e Objetivos do Projeto:
        </h3>
        <p>
          O projeto SafyConcierge tem como objetivo utilizar um robô móvel para prestar serviços de concierge em ambiente de
          cuidados de saúde, automatizando os processos e logística interna e por outro reduzindo o contato social entre profissionais e/ou pacientes.
        </p>
        <br />
        <section className="col-12 col-md-4 p-0">
          <Image
            alt="SafyConcierge"
            src={image1}
          />
        </section>
        <br />
        <br />
        <p>
          Assim propomos, a partir de uma plataforma de logística e de uma plataforma robótica existentes, fazer as devidas
          adaptações e disponibilizar ao mercado uma solução integrada que permita:
        </p>
        <br />
        <ul>
          <li>
            Gerir os recursos da organização: fluxos, disponibilidades e afetações;
          </li>
          <li>
            Automatizar a logística interna usando um robô móvel;
          </li>
          <li>
            Telepresença móvel;
          </li>
        </ul>
        <br />
        <section className="col-12 col-md-4 p-0">
          <Image
            alt="SafyConcierge"
            src={image2}
          />
        </section>
        <br />
        <br />
        <p>
          A plataforma SafyConcierge, não só pode fazer a gestão de equipamentos e inventário essenciais a um hospital ou uma
          instituição (exemplo lares ou cuidados paliativos), como evita o contacto social, uma vez que o robot substituiu a
          deslocação de um funcionário para realizar certas tarefas como distribuir medicação, entregar correspondência,
          pequenos objetos e alimentação, e acompanhamento dos pacientes via telepresença.
        </p>
        <br />
        <section className="col-12 col-md-4 p-0">
          <Image
            alt="SafyConcierge"
            src={image3}
          />
        </section>
        <br />
        <br />
        <Button
          context="primary"
          external
          linkComponent={Link}
          linkProps={{ to: 'https://www.you-ship.com/site2/pt/safyconcierge' }}
          noArrow
          text="Consultar min-site"
        />
        </div>
    </>
    </BasicLayout>
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      title="home.banner.title"
    />

  </Layout>
);

export default SafyConciergePage;
